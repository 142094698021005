import React, { useState } from 'react';
import './Header.css'
import Logo from '../../assets/logo.png';
import Bars from '../../assets/bars.png';
import { Link as ScrollLink } from "react-scroll";

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <div className="header">
      <img src={Logo} alt="" className='logo' />
      {menuOpened === false && mobile === true ? (
        <div
          style={{
            backgroundColor: 'var(--appColor)',
            padding: '0.5rem',
            borderRadius: '5px',
          }}
          onClick={() => setMenuOpened(true)}
        >
          <img src={Bars} alt="" style={{ width: '1.5rem', height: '1.5rem' }} />
        </div>
      ) : (
        <ul className='header-menu'>
          <li onClick={() => setMenuOpened(false)}>
            <ScrollLink to='home' smooth={true} duration={500}>Home</ScrollLink>
          </li>
          <li onClick={() => setMenuOpened(false)}>
            <ScrollLink to='programs' smooth={true} duration={500}>Programs</ScrollLink>
          </li>
          <li onClick={() => setMenuOpened(false)}>
            <ScrollLink to='why-us' smooth={true} duration={500}>Why us</ScrollLink>
          </li>
          <li onClick={() => setMenuOpened(false)}>
            <ScrollLink to='plans' smooth={true} duration={500}>Plans</ScrollLink>
          </li>
          <li onClick={() => setMenuOpened(false)}>
            <ScrollLink to='testimonials' smooth={true} duration={500}>Testimonials</ScrollLink>
          </li>
        </ul>
      )}
    </div>
  )
}

export default Header;
